import { Box } from '../Box/Box'
import { CircularProgress, CircularProgressProps } from '../CircularProgress/CircularProgress'
import { ElementType, FC, forwardRef, useMemo } from 'react'
import { IconComponent } from '../../types/util'
import { LiteralUnion } from 'type-fest'
import { MarginSxPropKeys, PositionSxPropKeys } from '../../types/sxPropKeys.types'
import { default as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { RestrictedSxProps } from '../../types/RestrictedSxProps.js'
import { Theme } from '@mui/system'
import { UrlObject } from 'url'
import { buttonColors, buttonVariants } from './Button.overrides'
import { circularProgressClasses } from '@mui/material/CircularProgress'
import { resolveHref } from 'next/dist/client/resolve-href'
import { unstable_useId as useId } from '@mui/material/utils'
import Router from 'next/router'

export type ButtonProps = Pick<
  MuiButtonProps,
  | 'autoFocus'
  | 'name'
  | 'children'
  | 'disabled'
  | 'form'
  | 'fullWidth'
  | 'href'
  | 'id'
  | 'key'
  | 'onClick'
  | 'ref'
  | 'size'
  | 'type'
> & {
  /**
   * The color of the component.
   * @note `upgrade` is a custom color that is only used for the upgrade button. It must be used with `variant: 'contained'`.
   * @default 'primary'
   */
  color?: (typeof buttonColors)[number]
  /**
   * If href is given, target is where the link will open to.
   * Commonly `"_blank"` to open in a new window.
   */
  target?: LiteralUnion<'_blank' | '_self' | '_parent' | '_top', string>
  /**
   * If href is given, sets `rel` attribute.
   */
  rel?: HTMLAnchorElement['rel']
  /**
   * Show a loading indicator.
   *
   * If passing a `startIcon` to the Button, the loading indicator
   * will be shown in place of the `startIcon` when `loading` is `true`.
   * If passing an `endIcon` to the Button, the loading indicator
   * will be shown in place of the `endIcon` when `loading` is `true`.
   * Otherwise, the loading indicator will be shown in place of the
   * Button's text when `loading` is `true`.
   */
  loading?: boolean
  /**
   * The variant to use.
   * @default 'contained'
   */
  variant?: (typeof buttonVariants)[number]
  /*
   * May only receive icons from `@mui/icons-material`
   */
  startIcon?: IconComponent
  /*
   * May only receive icons from `@mui/icons-material`
   */
  endIcon?: IconComponent
  component?: ElementType
  sx?: RestrictedSxProps<Theme, MarginSxPropKeys | PositionSxPropKeys>
  // created a separate prop because ButtonProps href is tied to many other components across packages
  hrefObject?: UrlObject
}

export type ButtonLoadingIndicatorProps = Pick<ButtonProps, 'color' | 'size' | 'variant'> &
  Pick<CircularProgressProps, 'aria-labelledby'> & {
    loadingPosition: 'start' | 'center' | 'end'
  }

const ButtonLoadingIndicator: FC<ButtonLoadingIndicatorProps> = ({
  ['aria-labelledby']: ariaLabelledBy,
  loadingPosition = 'center',
  color: colorProp = 'primary',
  size: sizeProp = 'medium',
  variant = 'contained'
}) => {
  const size = {
    small: '0.9375rem',
    medium: '1.041875rem',
    large: '1.25rem'
  }[sizeProp]

  let color: string
  if (variant === 'outlined') {
    if (['primary', 'secondary'].includes(colorProp)) {
      color = `onSurface.disabled`
    } else {
      color = `${colorProp}.main`
    }
  } else if (variant === 'contained') {
    if (['primary', 'secondary'].includes(colorProp)) {
      color = 'onSurface.disabled'
    } else {
      color = `${colorProp}.contrastText`
    }
  } else {
    color = `${colorProp}.main`
  }

  const outerMargin = sizeProp === 'small' ? -0.5 : -1
  const innerMargin = 2

  const ml = {
    start: outerMargin,
    center: 'unset',
    end: innerMargin
  }[loadingPosition]

  const mr = {
    start: innerMargin,
    center: 'unset',
    end: outerMargin
  }[loadingPosition]

  return (
    <Box
      sx={{
        ...(loadingPosition === 'center' && {
          position: 'absolute',
          visibility: 'visible',
          left: '50%',
          transform: 'translate(-50%)',
          color: 'action.disabled'
        }),
        [`& .${circularProgressClasses.root}`]: {
          display: 'inherit',
          color,
          ml,
          mr,
          svg: { height: size, width: size }
        }
      }}
    >
      <CircularProgress aria-labelledby={ariaLabelledBy} size={size} />
    </Box>
  )
}

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 *
 * @see https://mui.com/material-ui/react-button for more details.
 *
 * @note Component overrides are defined in [./Button.overrides.ts](./Button.overrides.ts).
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id: idProp,
      children,
      color = 'primary',
      disabled,
      loading,
      size = 'medium',
      startIcon: StartIcon,
      endIcon: EndIcon,
      variant = 'contained',
      hrefObject,
      ...props
    },
    ref
  ) => {
    if (color === 'upgrade' && variant !== 'contained') {
      throw new Error("Buttons with `color: 'upgrade'` must use `variant: 'contained'`.")
    }

    const id = useId(idProp) as string

    let loadingPosition: 'start' | 'center' | 'end'
    if (StartIcon) loadingPosition = 'start'
    else if (EndIcon) loadingPosition = 'end'
    else loadingPosition = 'center'

    const buttonContent = useMemo(() => {
      return loadingPosition === 'center' ? (
        <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</Box>
      ) : (
        <Box component={'span'}>{children}</Box>
      )
    }, [children, loading, loadingPosition])

    const buttonLoadingIndicator = useMemo(() => {
      return loading ? (
        <ButtonLoadingIndicator
          aria-labelledby={id}
          color={color}
          size={size}
          loadingPosition={loadingPosition}
          variant={variant}
        />
      ) : null
    }, [color, id, loading, loadingPosition, size, variant])
    const formattedHref = useMemo((): string | undefined => {
      if (hrefObject) {
        const [, resolvedUrl] = resolveHref(Router, hrefObject, true)
        return resolvedUrl
      } else return props.href
    }, [props.href, hrefObject])

    return (
      <MuiButton
        id={id}
        className={loading ? 'MuiButton-root--loading' : undefined}
        ref={ref}
        variant={variant}
        color={color}
        size={size}
        disabled={disabled || loading}
        startIcon={StartIcon && !loading ? <StartIcon /> : undefined}
        endIcon={EndIcon && !loading ? <EndIcon /> : undefined}
        {...props}
        href={formattedHref}
      >
        <>
          {loadingPosition === 'end' ? buttonContent : buttonLoadingIndicator}
          {loadingPosition === 'end' ? buttonLoadingIndicator : buttonContent}
        </>
      </MuiButton>
    )
  }
)

Button.displayName = 'Button'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    warning: false
    info: false
    upgrade: true
  }

  interface ButtonClasses {
    /** Styles applied to the root element if `variant="contained"` and `color="tertiary"`. */
    containedTertiary: string
    /** Styles applied to the root element if `variant="outlined"` and `color="tertiary"`. */
    outlinedTertiary: string
    /** Styles applied to the root element if `variant="text"` and `color="tertiary"`. */
    textTertiary: string
    /** Styles applied to the root element if `variant="contained"` and `color="upgrade"`. */
    containedUpgrade: string
  }
}
