import { AllPageInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  PREVIEW_HEIGHT,
  PREVIEW_WIDTH
} from '../../profile/DesktopProfile/components/FlowpagePreviewSection/constants'
import { generateOptimizedImagePath } from '../../../../../../utils/images'
import { getBgOpacity } from '@app/page/src/utils/theme'
import { theme } from '@dtx-company/design-system/src'
import styled from 'styled-components'

export const FlowpageContainer = styled(Box)<{ pageTheme?: AllPageInfoFragment['theme'] }>`
  position: relative;
  width: 100%;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100%;
  justify-content: space-between;
  scrollbar-width: none; /* Firefox only */

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 0px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const FlowpageBackground = styled(Box)<{ pageTheme?: AllPageInfoFragment['theme'] }>`
    position: fixed;
    pointer-events: none;
    inset: 0;
    opacity: ${({ pageTheme }) => getBgOpacity(pageTheme?.style)};
    background-image: ${({ pageTheme }) =>
      pageTheme?.backgroundImgUrl &&
      `url(${generateOptimizedImagePath({
        src: pageTheme?.backgroundImgUrl,
        width: PREVIEW_WIDTH,
        height: PREVIEW_HEIGHT
      })})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
`
