import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { getFlowpageHost } from '@dtx-company/true-common/src/utils/urls/host'

const DEFAULT_TITLE = 'Flowcode: Free QR Code Generator'
const DEFAULT_DESCRIPTION =
  'Flowcode is the #1 free QR code generator. Create a custom code with colors and designs, backed with privacy and analytics. Download your code today!'
const DEFAULT_KEYWORDS =
  'qr generator, qr code, qr, free qr code, flowcode, create qr code, qr maker, qr reader, code, dtc'
const DEFAULT_URI = FLOWCODE_ROOT
const DEFAULT_IMAGE = `${FLOWCODE_ROOT}/favicon/favicon-black.png`

const FP_FAVICON: Favicon = {
  ico: '/favicon/fp-favicon.ico',
  svg: '/favicon/fp-favicon.svg',
  appleTouchIcon: '/favicon/fp-apple-touch-icon.png',
  manifest: '/favicon/fp.webmanifest'
}
const DEFAULT_KEYWORDS_FP =
  'flow, own your flow,flowcode, flowpage,connect your social media profiles,grow your followers'
const DEFAULT_URI_FP = `${process.env.PROTOCOL}://${getFlowpageHost()}`
const DEFAULT_IMAGE_FP = DEFAULT_URI_FP + '/static/flowpage-logo.png'
export interface Meta {
  title?: string
  description?: string
  favicon?: Favicon
  keywords?: string
  url?: string
  image?: string
  canonical?: string
}

interface Favicon {
  ico?: string
  svg?: string
  appleTouchIcon?: string
  manifest?: string
}

// add page name to override default meta attributes
export const meta: { [key: string]: Meta } = {
  default: {
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    keywords: DEFAULT_KEYWORDS,
    url: DEFAULT_URI,
    favicon: {
      ico: '/favicon/favicon.ico',
      svg: '/favicon/favicon-black.svg',
      appleTouchIcon: '/favicon/apple-touch-icon.png',
      manifest: '/favicon/site.webmanifest'
    },
    image: DEFAULT_IMAGE
  },
  [Routes.ACCEPT_INVITE]: {
    title: 'Accept Invite | Flowcode',
    description: 'Accept Invite'
  },
  [Routes.ACCEPT_ORG_INVITE]: {
    title: 'Accept Org Invite | Flowcode'
  },
  [Routes.ACCOUNT]: {
    title: 'Account | Flowcode'
  },
  [Routes.ADMIN]: {
    title: 'Admin | Flowcode'
  },
  [Routes.ANALYTICS]: {
    title: 'Analytics | Flowcode'
  },
  [Routes.ANALYTICS_CODES]: {
    title: 'Flowcode Analytics | Flowcode'
  },
  [Routes.ANALYTICS_CONVERSIONS]: {
    title: 'Conversions Analytics | Flowcode'
  },
  [Routes.ANALYTICS_EVENTS]: {
    title: 'Analytics Events | Flowcode'
  },
  [Routes.ANALYTICS_INSIGHTS]: {
    title: 'Analytics Insights | Flowcode'
  },
  [Routes.ANALYTICS_PAGE]: {
    title: 'Flowpage Analytics | Flowcode'
  },
  [Routes.ANALYTICS_PAGES]: {
    title: 'Flowpage Analytics | Flowcode'
  },
  [Routes.ARCHIVE_ASSETS]: {
    title: 'Archive | Flowcode'
  },
  [Routes.ASSETS]: {
    title: 'Assets | Flowcode'
  },
  [Routes.AUTO]: {
    title: 'Studio | Flowcode'
  },
  [Routes.BRAND_KIT]: {
    title: 'Brand Kit | Flowcode'
  },
  [Routes.BUNDLE_DETAILS]: {
    title: 'Bundles | Flowcode'
  },
  [Routes.BUNDLES]: {
    title: 'Bundles | Flowcode'
  },
  [Routes.BUY_PLAN_PAGE]: {
    title: 'Buy Plan | Flowcode'
  },
  [Routes.CANVA_REDIRECT]: {
    title: 'Canva | Flowcode'
  },
  [Routes.CODE]: {
    title: 'Codes | Flowcode'
  },
  [Routes.CODE_NOT_FOUND]: {
    title: '404 | Flowcode'
  },
  [Routes.CODEE]: {
    title: 'Autobuilder | Flowcode'
  },
  [Routes.CODEE_CODE_CREATION]: {
    title: 'Autobuilder | Flowcode'
  },
  [Routes.CODES]: {
    title: 'Codes | Flowcode'
  },
  [Routes.CONFIRM_PASSWORD_RESET]: {
    title: 'Password Reset | Flowcode'
  },
  [Routes.CONTACT_SHARE]: {
    title: 'Flowcode | Contact Sharing'
  },
  [Routes.CONTACT2_FLOWTAG]: {
    title: 'Flowtag | Flowcode'
  },
  [Routes.CREATE_CODE_SUCCESS]: {
    title: 'Create Code Success | Flowcode'
  },
  [Routes.CREATE_FLOWCODE]: {
    title: 'Create Flowcode | Flowcode'
  },
  [Routes.CRM_FORMS]: {
    title: 'Forms | Flowcode'
  },
  [Routes.CRM_FORMS_EDITOR]: {
    title: 'Forms Editor | Flowcode'
  },
  [Routes.CRM_FORMS_SUBMISSIONS]: {
    title: 'Forms Submissions | Flowcode'
  },
  [Routes.CRM_SUBMISSIONS_PAGE]: {
    title: 'Flowpage Submissions | Flowcode'
  },
  [Routes.DASHBOARD]: {
    title: 'Dashboard | Flowcode'
  },
  [Routes.DISCOVER_FLOWCODE_ORG_ACTIVITY]: {
    title: 'Org Activity | Flowcode'
  },
  [Routes.VALIDATE_PRIVACY_REQUEST]: {
    title: 'Validate Privacy Request | Flowcode'
  },
  [Routes.DOMAINS]: {
    title: 'Domains | Flowcode'
  },
  [Routes.EDIT_CODE]: {
    title: 'Edit Code | Flowcode',
    description: 'Edit Flowcode Design'
  },
  [Routes.EDIT_STUDIO]: {
    title: 'Studio - Code Design | Flowcode'
  },
  [Routes.FLOWPAGE]: {
    title: 'Flowpage | Flowcode'
  },
  [Routes.FLOWPAGE_CHECKOUT_SUCCESS]: {
    title: 'Checkout | Flowcode'
  },
  [Routes.FOLDERS]: {
    title: 'Folders | Flowcode'
  },
  [Routes.FORGOT_PASSWORD]: {
    title: 'Forgot Password | Flowcode'
  },
  [Routes.FREE_QR_CODE_GENERATOR]: {
    title: 'Free QR Code Generator | Flowcode'
  },
  [Routes.FRONTEGG_SOCIAL_REDIRECT]: {
    title: 'Social Redirect | Flowcode'
  },
  [Routes.FRONTEGG_SSO_REDIRECT]: {
    title: 'SSO Redirect | Flowcode'
  },
  [Routes.GET_STARTED_FP]: {
    title: 'Get Started | Flowcode'
  },
  [Routes.IMPERSONATION]: {
    title: 'Impersonation | Flowcode'
  },
  [Routes.INDEX]: {
    title: 'Flowcode: Free QR Code Generator',
    description:
      'Flowcode is the #1 free QR code generator. Create a custom code with colors and designs, backed with privacy and analytics. Download your code today!'
  },
  [Routes.INTERNAL_TOOLS]: {
    title: 'Internal Tools | Flowcode'
  },
  [Routes.MAINTENANCE]: {
    title: 'Maintenance | Flowcode'
  },
  [Routes.MEMBER]: {
    title: 'Org Member | Flowcode'
  },
  [Routes.MY_ORG_INVITATIONS]: {
    title: 'Org Invitations | Flowcode'
  },
  [Routes.MY_ORG_MEMBERS]: {
    title: 'Org Members | Flowcode'
  },
  [Routes.MY_ORG_TEAM]: {
    title: 'Team | Flowcode'
  },
  [Routes.MY_ORG_TEAMS]: {
    title: 'Teams | Flowcode'
  },
  [Routes.NOT_FOUND]: {
    title: '404 | Flowcode'
  },
  [Routes.NOTICE]: {
    title: 'Privacy Policy | Flowcode'
  },
  [Routes.OPT_OUT]: {
    title: 'Opt Out | Flowcode'
  },
  [Routes.ORG]: {
    title: 'Flowcode | Organization'
  },
  [Routes.PAGE_ANALYTICS]: {
    title: 'Flowpage | Analytics',
    description: 'Analytics',
    keywords: DEFAULT_KEYWORDS_FP,
    image: DEFAULT_IMAGE_FP,
    url: DEFAULT_URI + Routes.PAGE_ANALYTICS,
    favicon: FP_FAVICON
  },
  [Routes.PAGE_EDITOR]: {
    title: 'Edit Flowpage | Flowcode'
  },
  [Routes.PAGE_TEMPLATE_EDITOR]: {
    title: 'Edit Flowpage Template | Flowcode'
  },
  [Routes.PAGES]: {
    title: 'Flowpage Management | Flowcode'
  },
  [Routes.PAUSED_ACCOUNT]: {
    title: 'Paused Account | Flowcode'
  },
  [Routes.PAY]: {
    title: 'Pay | Flowcode'
  },
  [Routes.PIXEL_MANAGEMENT]: {
    title: 'Universal Pixel | Flowcode'
  },
  [Routes.PRESET]: {
    title: 'Claim Flowcode | Flowcode'
  },
  [Routes.PRIVACY_REQUEST]: {
    title: 'Privacy Request | Flowcode'
  },
  [Routes.PRIVACY_REQUEST_SUBMITTED]: {
    title: 'Privacy Request Submitted | Flowcode'
  },
  [Routes.REPORT]: {
    title: 'Reports | Flowcode'
  },
  [Routes.REPORTS]: {
    title: 'Reports | Flowcode'
  },
  [Routes.SALESFORCE_ONBOARDING_SUCCESS]: {
    title: 'Flowcode x Salesforce'
  },
  [Routes.SEARCH_RESULTS]: {
    title: 'Search Results | Flowcode'
  },
  [Routes.SELECT_PLAN]: {
    title: 'Select Plan | Flowcode'
  },
  [Routes.SIGN_IN]: {
    title: 'Sign In | Flowcode'
  },
  [Routes.SIGN_UP]: {
    title: 'Sign Up | Flowcode'
  },
  [Routes.SSO_LOGIN]: {
    title: 'SSO Login | Flowcode'
  },
  [Routes.STUDIO]: {
    title: 'Studio  | Flowcode'
  },
  [Routes.STUDIO_COMPONENTS]: {
    title: 'Studio - Components | Flowcode'
  },
  [Routes.STUDIO_LIBRARY]: {
    title: 'Studio - Library | Flowcode'
  },
  [Routes.STUDIO_TEMPLATES]: {
    title: 'Studio - Templates | Flowcode'
  },
  [Routes.TEMPLATES_DISCOVER]: {
    title: 'Discover Templates | Flowcode'
  },
  [Routes.TEMPLATES_DISCOVER_CODES]: {
    title: 'Discover Code Templates | Flowcode'
  },
  [Routes.TEMPLATES_DISCOVER_PAGES]: {
    title: 'Discover Page Templates | Flowcode'
  },
  [Routes.TEMPLATES_YOUR_CODES]: {
    title: 'Your Flowcode Templates | Flowcode'
  },
  [Routes.TEMPLATES_YOUR_CREATE]: {
    title: 'Your Tempates - Create | Flowcode'
  },
  [Routes.TEMPLATES_YOUR_PAGES]: {
    title: 'Your Flowpage Templates | Flowcode'
  },
  [Routes.TEMPLATES_YOUR_TEMPLATES]: {
    title: 'Your Templates | Flowcode'
  },
  [Routes.TRASH]: {
    title: 'Archive | Flowcode'
  },
  [Routes.VIDEO_TOOL]: {
    title: 'Video QR Code Tool | Flowcode'
  }
}
