import {
  DATA_DOG_CLIENT_TOKEN,
  DATA_DOG_LOGS_SAMPLE_RATE,
  DATA_DOG_RUM_APPLICATION_ID,
  DATA_DOG_RUM_SAMPLE_RATE,
  HEAP_APP_ID
} from '../../constants/third-party'
import { FACEBOOK_APP_ID } from '../../constants/app'
import { Meta } from '../../constants/meta'
import { getPageMetaAttributes } from '../../utils/header'
import Head from 'next/head'

interface Props {
  page?: string
  injectedMeta?: Meta
}

const preconnectOrigins = [
  'https://flowcode-ui.cdn.prismic.io',
  'https://cdn.cookielaw.org',
  `https://cdn.heapanalytics.com/js/heap-${HEAP_APP_ID}.js`,
  'https://www.google-analytics.com',
  'https://www.googletagmanager.com'
]

const Preconnect = preconnectOrigins.map(origin => (
  <link key={origin} rel="preconnect" href={origin} crossOrigin="use-credentials" />
))

const universalKeywords = [
  'flow',
  'own your flow',
  'flowcode',
  'flowpage',
  'connect your social media profiles',
  'grow your followers'
]

export const CustomHead = ({ page, injectedMeta = {} }: Props): JSX.Element => {
  const { title, description, url, image, keywords, favicon, canonical }: Meta = {
    ...getPageMetaAttributes(page),
    ...injectedMeta
  }
  // Environment variables used here are passed at build time.
  const version = process.env.VERSION || 'unknown'
  const datadogService = `${process.env.DD_SERVICE}`
  let datadogEnv = `${process.env.APP_ENV}`
  if (datadogEnv == 'staging') {
    datadogEnv = 'stg'
  }
  if (datadogEnv == 'production') {
    datadogEnv = 'prod'
  }

  return (
    <Head>
      {/* Primary Meta Tags */}
      {title && <title key="title">{title}</title>}
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="title" content={title} key="meta-title" />
      <meta name="description" content={description} key="meta-desc" />
      <meta name="keywords" content={keywords || '' + universalKeywords} />

      {/* Site Verification */}
      <meta name="google-site-verification" content="msAjCeUf2nSedT5uCYXdP3DG_GrATT5GT9HCyS6czmw" />
      <meta name="facebook-domain-verification" content="h3zm29qz3oixxggkl9t8t3i9eusbrf" />

      {/* Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical || url} />
      <meta property="og:title" content={title} key="og-title" />
      <meta property="og:description" content={description} key="og-desc" />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />

      {/* Twitter  */}
      <meta property="twitter:card" content="summary_large_image" key="twitter-card" />
      <meta property="twitter:site" content="@flowcode" />
      <meta property="twitter:url" content={canonical || url} />
      <meta property="twitter:title" content={title} key="twitter-title" />
      <meta property="twitter:description" content={description} key="twitter-desc" />
      <meta property="twitter:image" content={image} />

      {/* Favicon */}
      <link rel="icon" href={favicon?.ico} />
      <link rel="icon" href={favicon?.svg} type="image/svg+xml" />
      <link rel="apple-touch-icon" href={favicon?.appleTouchIcon} />
      <link rel="manifest" href={favicon?.manifest} />

      {/* Canonical URL */}
      {canonical && <link rel="canonical" href={canonical} />}

      {Preconnect}
      {/* Datadog RUM */}
      <script
        type="text/plain"
        className="optanon-category-C0002"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,u,n,d) {
            h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
            d=o.createElement(u);d.async=1;d.src=n
            n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
          })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
          window.DD_RUM.onReady(function() {
            window.DD_RUM.init({
              clientToken: '${DATA_DOG_CLIENT_TOKEN}',
              applicationId: '${DATA_DOG_RUM_APPLICATION_ID}',              
              service: 'app.flowcode.com',
              env: '${datadogEnv}',
              version: '${version}',
              sessionSampleRate: ${DATA_DOG_RUM_SAMPLE_RATE},
              sessionReplaySampleRate: 0,
              trackUserInteractions: false,
              trackResources: true,
              trackLongTasks: true,
              defaultPrivacyLevel: 'mask-user-input',
            });
          })           
          `
        }}
      />
      {/* Datadog browser logs */}
      <script
        type="text/plain"
        className="optanon-category-C0002"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,u,n,d) {
              h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
              d=o.createElement(u);d.async=1;d.src=n
              n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
            })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-logs.js','DD_LOGS')
            window.DD_LOGS.onReady(function() {
              window.DD_LOGS.init({
                clientToken: '${DATA_DOG_CLIENT_TOKEN}',                
                forwardErrorsToLogs: '${process.env.NODE_ENV === 'production'}',
                sessionSampleRate: ${DATA_DOG_LOGS_SAMPLE_RATE},
                service: '${datadogService}',
                env: '${datadogEnv}',
                version: '${version}',
                silentMultipleInit: true
              })
            })
          `
        }}
      />
    </Head>
  )
}
